import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Bringing Polis to My Political Context`}</h1>
    <p>{`We often get inquiries from passionate citizens who have come across this work in `}<a parentName="p" {...{
        "href": "/media-coverage",
        "title": "media coverage"
      }}>{`media coverage`}</a>{`. We're really glad you're here. Ready to get involved?`}</p>
    <p>{`Here's our best advice after years of working in this space. Read the `}<a parentName="p" {...{
        "href": "/knowledge-base",
        "title": "knowledge base"
      }}>{`knowledge base`}</a>{` in depth, especially the sections on `}<a parentName="p" {...{
        "href": "/media-coverage",
        "title": "media coverage"
      }}>{`media coverage`}</a>{` and `}<a parentName="p" {...{
        "href": "/Case-studies",
        "title": "⚗️ Case studies"
      }}>{`⚗️ Case studies`}</a>{`. Then reach out respectfully to progressive journalists in your town or city, pass them `}<a parentName="p" {...{
        "href": "/Case-studies",
        "title": "⚗️ Case studies"
      }}>{`⚗️ Case studies`}</a>{` and answer the questions you can, and suggest they get in contact with us at `}<a parentName="p" {...{
        "href": "/about"
      }}>{`CompDem`}</a>{`, as CompDem provides `}<a parentName="p" {...{
        "href": "/services",
        "title": "services"
      }}>{`services`}</a>{` free of charge to independent news organizations as part of its `}<a parentName="p" {...{
        "href": "/about"
      }}>{`mission`}</a>{`. Free data science services! WooHoo!`}</p>
    <p>{`Or, you could, of course, `}<a parentName="p" {...{
        "href": "https://www.wired.co.uk/article/taiwan-democracy-social-media"
      }}>{`barricade parliament and win concessions`}</a>{` for `}<a parentName="p" {...{
        "href": "https://medium.com/@shuyanglin/how-to-start-something-like-vtaiwan-in-your-country-7fb9399e8339"
      }}>{`scaled-up participatory rulemaking`}</a>{`. Be sure to call us if you do.`}</p>
    <p>{`The choice is yours.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      